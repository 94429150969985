.container {
    font-size: 15px;
    font-weight: bold;
    line-height: 1.35;

    p {
        margin-bottom: 10px;
    }

    em {
        font-style: italic;
    }

    small {
        font-size: 12px;
    }

    a {
        font-weight: bold;
        text-decoration: underline;
    }

    strong {
        font-size: 20px;
        font-weight: bold;
    }

    @media (--small-viewport) {
        font-size: 18px;

        p {
            margin-bottom: 20px;
        }

        strong {
            font-size: 24px;
        }
    }
}

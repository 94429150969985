.container {
    display: flex;
    min-height: 250px;
    align-items: center;
    justify-content: center;

    .button {
        display: block;
        padding: 15px 20px;
        background-position: -10px 0;
        background-size: calc(100% + 20px) 100%;
        color: #000;
        font-family: var(--font-garage);
        font-size: 46px;
        text-transform: uppercase;
        transition: background-image 1s ease-in-out;

        @media (--small-viewport) {
            font-size: 60px;
        }

        @media (--large-viewport) {
            font-size: 66px;
        }
    }

    [data-theme='cdpq'] & {
        button {
            background-image: linear-gradient(90deg, #F6FC82, #D9FB50);

            &:hover {
                background-image: linear-gradient(90deg, #D9FB50, #F6FC82);
            }
        }
    }

    [data-theme='gaspesie'] & {
        button {
            background: var(--color-blue);

            &:hover {
                opacity: 0.8;
            }
        }
    }
}

.container {
    position: relative;
    width: 100%;

    .top {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .button {
        width: 100%;
        box-sizing: border-box;
        padding: 5px 10px;
        border: 5px solid transparent;
        background-image: linear-gradient(90deg, #F6FC82, #D9FB50);
        background-position: -10px 0;
        background-size: calc(100% + 20px) 100%;
        font-family: var(--font-agrandir, 'sans-serif');
        font-size: 24px;
        font-weight: 700;
        line-height: 1;
        text-align: left;
        text-transform: none;
        transition: border-color 0.15s ease-out, background-image 1s ease-in-out;

        .label {
            display: inline-block;
            padding-top: 4px;
        }

        &:hover {
            border: 5px solid #D9FB50;
        }
    }

    .letter {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 12px 8px;
        border-radius: 50%;
        margin-right: 10px;
        background-color: rgb(0 0 0 / 0.3);
        font-family: var(--font-agrandir, 'sans-serif');
        font-size: 16px;
        font-weight: 700;
        line-height: 1;
        text-transform: uppercase;
    }

    .description {
        overflow: hidden;
        height: 0;
        max-height: 0;
        font-family: var(--font-agrandir, 'sans-serif');
        font-size: 16px;
        font-weight: 500;
        line-height: 1.28;
        text-transform: none;
        transition: max-height 0.33s ease-out, height 0.33s ease-out;

        p {
            margin-bottom: 10px;
            opacity: 0;
            transition: opacity 0.33s ease-out;
        }

        &.open {
            height: auto;
            max-height: none;
            padding-top: 1rem;

            p {
                opacity: 1;
            }
        }

        p:last-child {
            padding-bottom: 0;
            margin-bottom: 10px;
        }
    }

    .slogan {
        position:absolute;
        top: -30px;
        right: -40px;
        opacity: 0;
        pointer-events: none;
        transform: rotate(0) scale(0.7);
        transition: transform 0.33s ease-out, opacity 0.33s ease-out;

        &.valid {
            transform: rotate(6deg) scale(0.5);
        }

        &.invalid {
            transform: rotate(-6deg) scale(0.5);
        }

        &.selected {
            opacity: 1;
        }

        @media (--medium-viewport) {
            top: -15px;
            right: 10px;

            &.valid {
                transform: rotate(6deg) scale(0.8);
            }

            &.invalid {
                transform: rotate(-6deg) scale(0.8);
            }
        }
    }

    &.invalid {
        .button {
            background-image: linear-gradient(90deg, #FFA490, #FFA490);
        }
    }

    &.valid {
        .button {
            border: 5px solid #FFF;
            background-image: linear-gradient(90deg, #AAF9C0, #AAF9C0);
        }
    }

    &.small {
        .button {
            font-size: 18px;
        }
    }

    &.large {
        .button {
            font-family: var(--font-garage);
            font-size: 42px;

            .label {
                padding-top: 0;
            }
        }
    }

    &.selected {
        .button {
            border: 5px solid #CB4376;
            background-image: linear-gradient(90deg, #BF2155, #CB4376);
            color: #FFF;
        }
    }

    &.disabled {
        .button {
            cursor: default;
        }

        &.valid {
            .button {
                pointer-events: all;
            }
        }
    }

    [data-theme='gaspesie'] & {
        .button, .letter, .description {
            font-family: var(--font-dehors);
            font-size: 18px;
        }

        .button {
            padding: 10px;
            border: 5px solid var(--color-white);
            background: var(--color-blue);
            color: var(--color-black);
        }

        .letter {
            padding: 10px 12px;
            border-radius: 4px;
        }

        .label {
            padding-top: 0;
        }

        .description {
            font-size: 16px;
        }

        &.selected {
            .button {
                border: 5px solid var(--color-white);
                background: var(--color-teal);
                color: var(--color-white);
            }
        }
    }
}


.container {
    position: relative;
    display: block;
    padding: 0;
    padding: 10px 20px;
    border: 0;
    border-radius: 20px;
    margin: 0;
    margin: 0 auto;
    appearance: none;
    background: transparent;
    background-color: #FFF;
    color: inherit;
    color: #000;
    cursor: pointer;
    font-family: var(--font-agrandir, 'sans-serif');
    font-size: 24px;
    text-transform: uppercase;
}

.container {
    padding: 20px 10px;
    font-family: var(--font-agrandir, 'sans-serif');
    font-size: 20px;
    font-weight: 900;
    line-height: 1.2;
    text-transform: uppercase;

    @media (--small-viewport) {
        font-size: 22px;
    }
}

.container {
    position: relative;
    display: inline-block;
    padding: 9px 10px 7px;
    border-radius: 20px;
    margin: 0 auto;
    background-color: #000;
    color: #FFF;
    font-family: var(--font-agrandir, 'sans-serif');
    font-size: 16px;
    font-weight: 700;

    @media (--medium-viewport) {
        font-size: 20px;
    }

    [data-theme='gaspesie'] & {
        padding: 10px;
        background-color: transparent;
        color: #000;
        font-family: var(--font-garage);
        font-size: 24px;
        text-transform: uppercase;

        @media (--medium-viewport) {
            font-size: 30px;
        }
    }
}
